<template>
    <canvas
      height="800"
      width="800"
      ref="cav"
      class="cav"
    />
</template>

<script src="./renderer-viewer.ts"></script>

<style scoped>
.cav {
  margin: 3px;
  border: 1px solid blue;
}
</style>
